<div class="">
    <div class="main-box clearfix" style="min-height: 200px;">
        <div class="main-box-body clearfix col-lg-10 col-lg-offset-1" style="margin-top:50px">
            <p class="text-center">
                <!-- Upload CSV -->
                Upload CSV or Excel file.
                <a class="cursor-pointer" (click)="showFileFormat(viewSkillsSampleFormat)">Click here</a> for
                format.
            </p>
            <div>
                <form name="fileUploadDropzone" id="fileUploadDropzone" class="dropzone dz-clickable" action=""
                    enctype="multipart/form-data">
                    <div class="dz-a dz-default dz-message">
                        <span>
                            <h3>Drop file here to upload</h3>(or click)
                        </span>
                    </div>
                </form>

            </div>
            <div class="row buttonrow mt-5 mb-3">
                <div class="col-xs-12 col-sm-offset-4 col-sm-4">
                    <button type="button" class="btn btn-success col-xs-12"
                        [disabled]="!fileExistsFlag || disableUploadButtonFlag" id="uploadFile" (click)="uploadFile()">
                        <span class="pr-2"><i class="fa fa-upload"></i></span>
                        <span *ngIf="!fileUploadInProgressFlag">Upload File</span>
                        <span *ngIf="fileUploadInProgressFlag">Uploading File in progress</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #viewSkillsSampleFormat>
    <div class="modal-header" style="padding: 15px !important; border-bottom: 1px solid #e5e5e5;">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
            (click)="closeFileFormateModal()">×</button>
        <h4 class="modal-title wrap-text-in ng-binding">
            <span *ngIf="modalData.type === 'uploadSkills'">Upload Skills Format</span>
            <span *ngIf="modalData.type === 'uploadRequisitionEntities' && modalData.action === 'degrees'">Upload Degrees Format</span>
            <span *ngIf="modalData.type === 'uploadRequisitionEntities' && modalData.action === 'fieldOfStudy'">Upload Field of Study Format</span>
            <span *ngIf="modalData.type === 'uploadRequisitionEntities' && modalData.action === 'roles'">Upload Roles Format</span>
            <span *ngIf="modalData.type === 'uploadRequisitionEntities' && modalData.action === 'certificates'">Upload Certificates Format</span>
            <span *ngIf="modalData.type === 'uploadRequisitionEntities' && modalData.action === 'jdActions'">Upload JD Action Word Format</span>
        </h4>
    </div>

    <div class="modal-body">
        <app-upload-file-sample-format [uploadType]="modalData"></app-upload-file-sample-format>
    </div>
</ng-template>