import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentsSyncMessageModule } from './assessments-sync-message/assessments-sync-message.module';
import { FormsModule } from '@angular/forms';
import { AddEditCandidateContactOneModule } from './add-edit-candidate-contact-one/add-edit-candidate-contact-one.module';
import { CandidateAdditionalDetailsModule } from './candidate-additional-details/candidate-additional-details.module';
import { AddEditCandidateContactModule } from './add-edit-candidate-contact/add-edit-candidate-contact.module';
import { FourDotFivePhoneNumberModule } from './four-dot-five-phone-number/four-dot-five-phone-number.module';
import { ManageEntityLocationModule } from './manage-entity-location/manage-entity-location.module';
import { InfoIconModule } from './info-icon/info-icon.module';
import { ActionLessHeaderModule } from './action-less-header/action-less-header.module';
import { CandidateCardIconsModule } from './candidate-card-icons/candidate-card-icons.module';
import { WorkLocationsIconModule } from './work-locations-icon/work-locations-icon.module';
import { CandidateActionsModule } from './candidate-actions/candidate-actions.module';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { RequisitionCardIconsModule } from './requisition-card-icons/requisition-card-icons.module';
import { RecentActivitiesModule } from './recent-activities/recent-activities.module';
import { EntityActivitiesModule } from './entity-activities/entity-activities.module';
import { SetCandidateAvailabilityOrJobLeftDateModule } from './set-candidate-availability-or-job-left-date/set-candidate-availability-or-job-left-date.module';
import { DoNotHireModule } from './do-not-hire/do-not-hire.module';
import { ExternalNotesModule } from './external-notes/external-notes.module';
import { UserCommunicationModule } from './user-communication/user-communication.module';
import { JobMatchActionsModule } from './job-match-actions/job-match-actions.module';
import { DeleteEntityModule } from './delete-entity/delete-entity.module';
import { RequisitionSkillsModule } from './requisition-skills/requisition-skills.module';
import { UploadFilesModule } from './upload-files/upload-files.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    AssessmentsSyncMessageModule,
    FourDotFivePhoneNumberModule,
    ManageEntityLocationModule,
    AddEditCandidateContactOneModule,
    CandidateAdditionalDetailsModule,
    AddEditCandidateContactModule,
    InfoIconModule,
    ActionLessHeaderModule,
    CandidateCardIconsModule,
    WorkLocationsIconModule,
    CandidateActionsModule,
    RequisitionCardIconsModule,
    RecentActivitiesModule,
    EntityActivitiesModule,
    SetCandidateAvailabilityOrJobLeftDateModule,
    DoNotHireModule,
    ExternalNotesModule,
    UserCommunicationModule,
    JobMatchActionsModule,
    DeleteEntityModule,
    RequisitionSkillsModule,
    UploadFilesModule
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
