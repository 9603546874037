import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { SkillActivityFilter } from 'src/app/core/models/skillActivityFilter';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { DateTimeUtilityService } from 'src/app/core/services/date-time-utility.service';
import { JobService } from 'src/app/core/services/job.service';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-req-entities-activities',
  templateUrl: './req-entities-activities.component.html',
  styleUrls: ['./req-entities-activities.component.css']
})
export class ReqEntitiesActivitiesComponent implements OnInit {

  @Input() entityType;
  @Input() multiEntityHeading;
  @Input() singleEntityHeading;

  viewActivityEntityModal: BsModalRef;
  viewMultiEntityUpdateTemplateModal: BsModalRef;

  activityFilter: any;
  activityFilterCopy: any;
  startDate: any;
  endDate: any;
  startTime: any;
  endTime: any;
  activityTypes: any = [];
  users: any = [];
  activities: any = [];
  uploadedEntities: any = [];
  multiUploadEntityActivityDetails: any = [];
  multiUploadEntityActivityHeading: String = "Updated";

  loadingFlag: boolean = false;
  isFiltersEnabled: boolean = false;

  constructor(
    private jobService: JobService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private userService: UserService,
    private dateTimeUtilityService: DateTimeUtilityService,
    private skillsService: ManageSkillsService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit() {
    this.activityFilter = new SkillActivityFilter();
    this.activityFilterCopy = new SkillActivityFilter();

    this.resetFilters();
    this._getFilters();
    this._setSearchPanel();
    this.initializeDatePickers(() => {
      this.getActivities();
    });
  }

  resetFilters() {
    this.activityFilter.activityOriginatorType = "";
    this.activityFilter.operation = "";
    this.activityFilter.performedBy = "";
    this.activityFilter.startDate = "";
    this.activityFilter.endDate = "";
    this.startDate = '';
    this.endDate = '';
    this._resetDateTimePicker();
  }

  _resetDateTimePicker() {
    ($("#startTime") as any).timepicker("setTime", "12:00 AM");
    ($('#endTime') as any).timepicker("setTime", "11:59 PM");
  }

  _getFilters() {
    this.getActivityTypes();
    this.getUsers();
  }

  getActivityTypes() {
    this.jobService.getActivityTypesForFilter((data) => {
      data.forEach((activityType) => {
        if (activityType.value.includes('SKILL')) {
          this.activityTypes.push(activityType);
        }
      });
      this.activityTypes.forEach(activity => {
        if (activity.name == 'Skill Added') {
          activity.name = `${this.multiEntityHeading} Added`;
          activity.value = 'added'
        } else if (activity.name == 'Skill Updated') {
          activity.name = `${this.multiEntityHeading} Updated`;
          activity.value = 'updated'
        } else if (activity.name == 'Skill Deleted') {
          activity.name = `${this.multiEntityHeading} Deleted`;
          activity.value = 'deleted'
        } else if (activity.name == 'Skill Uploaded') {
          activity.name = `${this.multiEntityHeading} Uploaded`;
          activity.value = 'uploaded'
        }
      })
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getUsers() {
    this.userService.getCompanyUsersForActivities((data) => {
      this.users = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _setSearchPanel() {
    $(document).ready(() => {
      $("#search-panel").hide();
      $(document).off("click", "#open-filter").on("click", "#open-filter",
        () => {
          $("#search-panel").animate({
            height: 'toggle'
          });
        });
    });
  }

  initializeDatePickers(successCallback) {
    ($(document) as any).ready(() => {
      let currentDate = new Date();

      ($('#startDate') as any).datepicker({
        format: "M d, yyyy",
        endDate: currentDate,
        weekStart: 1,
        autoclose: true
      });

      ($("#startTime") as any).timepicker("setTime", "12:00 AM");
      this.startTime = ($('#startTime') as any).val();

      ($('#endDate') as any).datepicker({
        format: "M d, yyyy",
        endDate: currentDate,
        weekStart: 1,
        autoclose: true
      });

      ($('#startTime') as any).timepicker({
        minuteStep: 5,
        showSeconds: false,
        showMeridian: true,
        disableFocus: true,
        defaultTime: '12:00 AM'
      });

      ($('#endTime') as any).timepicker({
        minuteStep: 5,
        showSeconds: false,
        showMeridian: true,
        disableFocus: true,
        defaultTime: '11:59 PM'
      });

      ($('#endTime') as any).timepicker("setTime", "11:59 PM");
      this.endTime = ($('#endTime') as any).val();


      ($('#startDate') as any).datepicker().on('changeDate', (e) => {
        this.startDate = ($('#startDate') as any).val();
        this.activityFilter.startDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.startDate + ' ' + this.startTime));
        this.getActivities();
      });

      ($('#endDate') as any).datepicker().on('changeDate', (e) => {
        this.endDate = ($('#endDate') as any).val();
        this.activityFilter.endDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.endDate + ' ' + this.endTime));
        this.getActivities();
      });

      ($('#startTime') as any).timepicker().on('changeTime.timepicker', (e) => {
        this.startTime = ($('#startTime') as any).val();
        this.activityFilter.startDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.startDate + ' ' + this.startTime));
        setTimeout(() => {
          this.getActivities();
        }, 100);
      });

      ($('#endTime') as any).timepicker().on('changeTime.timepicker', (e) => {
        this.endTime = ($('#endTime') as any).val();
        this.activityFilter.endDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.endDate + ' ' + this.endTime));
        setTimeout(() => {
          this.getActivities();
        }, 100);
      });
      if (successCallback) {
        successCallback();
      }
    });
  }

  getActivities() {
    if (this.entityType === 'degrees') {
      this.getDegreesActivities();
    } else if (this.entityType === 'fieldOfStudy') {
      this.getFieldOfStudyActivities()
    } else if (this.entityType === 'roles') {
      this.getRolesActivities()
    } else if (this.entityType === 'certificates') {
      this.getCertificatesActivities()
    } else if (this.entityType === 'jdActions') {
      this.getJdActionsActivities()
    }
  }

  getDegreesActivities() {
    this.setFilters();
    this.loadingFlag = true;
    this.skillsService.getAllDegreesActivities(this.activityFilter, (data) => {
      this.setActivities(data);
    }, (error) => {
      this.loadingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getFieldOfStudyActivities() {
    this.setFilters();
    this.loadingFlag = true;
    this.skillsService.getAllFieldOfStudyActivities(this.activityFilter, (data) => {
      this.setActivities(data);
    }, (error) => {
      this.loadingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getRolesActivities() {
    this.setFilters();
    this.loadingFlag = true;
    this.skillsService.getAllCandidateRolesActivities(this.activityFilter, (data) => {
      this.setActivities(data);
    }, (error) => {
      this.loadingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getCertificatesActivities() {
    this.setFilters();
    this.loadingFlag = true;
    this.skillsService.getAllCertificationsActivities(this.activityFilter, (data) => {
      this.setActivities(data);
    }, (error) => {
      this.loadingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getJdActionsActivities() {
    this.setFilters();
    this.loadingFlag = true;
    this.skillsService.getAllJdActionsActivities(this.activityFilter, (data) => {
      this.setActivities(data);
    }, (error) => {
      this.loadingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  setFilters() {
    if (this.activityFilter.operation == 'uploaded') {
      this.activityFilter.activityOriginatorType = 'FILE';
    } else if (this.activityFilter.operation != '') {
      this.activityFilter.activityOriginatorType = 'SCREEN';
    } else {
      this.activityFilter.activityOriginatorType = '';
    }
    //settings filter 
    if (this.activityFilter.startDate == 'Invalid date') {
      delete this.activityFilter.startDate;
      delete this.activityFilterCopy.startDate;
    } else {
      this.activityFilterCopy.startDate = this.activityFilter.startDate;
    }
    if (this.activityFilter.endDate == 'Invalid date') {
      delete this.activityFilter.endDate;
      delete this.activityFilterCopy.endDate;
    } else {
      this.activityFilterCopy.endDate = this.activityFilter.endDate;
    }
    if ((this.activityFilterCopy.activityOriginatorType == "" || !this.activityFilterCopy.activityOriginatorType)
      && (this.activityFilterCopy.operation == "" || !this.activityFilterCopy.operation)
      && (this.activityFilterCopy.performedBy == "" || !this.activityFilterCopy.performedBy)
      && (this.activityFilterCopy.startDate == "" || !this.activityFilterCopy.startDate)
      && (this.activityFilterCopy.endDate == "" || !this.activityFilterCopy.endDate)) {
      this.isFiltersEnabled = false;
    } else {
      this.isFiltersEnabled = true;
    }
  }

  setActivities(data: any) {
    this.activities = [];
    if (data && data.entityActivities) {
      let activities = [];
      data.entityActivities.forEach(activity => {
        activity.multiUpdateFlow = false;
        if (activity.activityOriginatorType == 'SCREEN') {
          this._setActivityMessageBadges(activity);
          if (activity.results && activity.results.length > 0) {
            activity.mainText = this._getCreatedByText(activity.results[0]);
            activity.operation = activity.results[0].operation;
            if (activity.multiUpdateFlow && activity.results.length > 1) {
              let updatedActivities = [];
              activity.results.forEach(entity => {
                if (entity.operation == 'update') {
                  this.setUpdateEntity(updatedActivities, entity);
                }
              });
              let updatedEntityActivityMessage = updatedActivities.length > 10 ? `${updatedActivities.slice(0, 10).join(" ")} ...` : updatedActivities.join(" ");
              activity.activityDisplayValue = `${updatedActivities.length} ${this.multiEntityHeading} Updated : ${updatedEntityActivityMessage}`;
            } else if (this._checkIfMultiDelete(activity)) {
              activity.multiUpdateFlow = true;
              let deletedEntities = [];
              activity.results.forEach(entity => {
                this.setDeleteEntity(deletedEntities, entity);
              });
              let deletedEntityActivityMessage = deletedEntities.length > 10 ? `${deletedEntities.slice(0, 10).join(" ")} ...` : deletedEntities.join(" ");
              activity.activityDisplayValue = `${activity.results.length} ${this.multiEntityHeading} Deleted: ${deletedEntityActivityMessage}`;
            } else {
              activity.activityDisplayValue = this._getSkillText(activity.results);
            }
            activities.push(activity);
          }
        } else {
          activity.activityDisplayValue = `File Uploaded <span class="badge badge-primary">${activity.originalFileName}</span>`;
          activity.mainText = this._getCreatedByText(activity);
          activities.push(activity);
        }
      });
      this.activities = [...activities];
    } else {
      this.activities = [];
    }
    this.loadingFlag = false;
  }

  setUpdateEntity(updatedActivities, entity) {
    if (this.entityType === 'degrees') {
      updatedActivities.push(`<span class="badge badge-primary">${entity.degree}</span>`);
    } else if (this.entityType === 'fieldOfStudy') {
      updatedActivities.push(`<span class="badge badge-primary">${entity.fieldOfStudy}</span>`);
    } else if (this.entityType === 'roles') {
      updatedActivities.push(`<span class="badge badge-primary">${entity.role}</span>`);
    } else if (this.entityType === 'certificates') {
      updatedActivities.push(`<span class="badge badge-primary">${entity.name}</span>`);
    } else if (this.entityType === 'jdActions') {
      updatedActivities.push(`<span class="badge badge-primary">${entity.actionword}</span>`);
    }
  }

  setDeleteEntity(deletedEntities, entity) {
    if (this.entityType === 'degrees') {
      deletedEntities.push(`<span class="badge badge-danger">${entity.degree}</span>`);
    } else if (this.entityType === 'fieldOfStudy') {
      deletedEntities.push(`<span class="badge badge-danger">${entity.fieldOfStudy}</span>`);
    } else if (this.entityType === 'roles') {
      deletedEntities.push(`<span class="badge badge-danger">${entity.role}</span>`);
    } else if (this.entityType === 'certificates') {
      deletedEntities.push(`<span class="badge badge-danger">${entity.name}</span>`);
    } else if (this.entityType === 'jdActions') {
      deletedEntities.push(`<span class="badge badge-danger">${entity.actionword}</span>`);
    }
  }

  _setActivityMessageBadges(activity) {
    if (activity.results && activity.results.length > 0) {
      activity.results.forEach(result => {
        this.setSkillBadges(result);
      });
    }
  }

  _getCreatedByText(activity) {
    return activity.createdBy !== '' ?
      `<span> by <span class="emerald"><i>${activity.createdBy}</i></span></span>`
      : '';
  }

  _checkIfMultiDelete(activity) {
    if (activity.results && activity.results.length > 1) {
      return activity.results.every(result => result.operation === 'deleted');
    }
    return false;
  }

  _getSkillText(activities) {
    let activity: any = {
      activityMessage: ""
    };

    activities.forEach((data, key) => {
      let addBreak = "";
      if (data && data.activityMessage) {
        addBreak = activities.length > 1 ? '<br>' : "";
        activity.activityMessage = activity.activityMessage + data.activityMessage + addBreak;
      }
    });

    return activity.activityMessage;
  }

  setSkillBadges(result) {
    let badge = "badge-primary";
    if (result.operation == 'added') {
      badge = "badge-success";
    } else if (result.operation == 'deleted' || result.operation == 'error') {
      badge = "badge-danger";
      if (result.activityMessage && result.activityMessage != "") {
        result.activityMessage = result.activityMessage.charAt(0).toUpperCase() + result.activityMessage.slice(1);
      }
    }
    if (result.activityMessage && result.activityMessage != "") {
      result.activityMessage = result.activityMessage.replace(/"([^"]*)"/g, `<span class="badge ${badge}">$1</span>`);
    }
  }

  viewUploadEntities(uploadedEntities, template: TemplateRef<any>) {
    this.uploadedEntities = uploadedEntities;
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    const modalData: any = {};
    config.class = "custom-modal-xl custom-ngx-modal";
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.viewActivityEntityModal = this.bsModalService.show(template, config);
  }

  ViewMultiEntitiesUpdateModal(activity, template: TemplateRef<any>) {
    this.multiUploadEntityActivityDetails = [];
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    const modalData: any = {};
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.multiUploadEntityActivityDetails = activity.results;
    if (activity.results[0].operation == 'deleted') {
      config.class = "custom-ngx-modal";
      this.multiUploadEntityActivityHeading = "Deleted";
    } else {
      config.class = "modal-lg custom-ngx-modal";
      this.multiUploadEntityActivityHeading = "Updated";
    }
    this.viewMultiEntityUpdateTemplateModal = this.bsModalService.show(template, config);
  }

}
