<div class="row">
    <div *ngFor="let skill of skills; index as i;" class="col-xs-12" id="skill-{{skillType}}">
        <div class="d-flex align-items-center pb-2">
            <div *ngIf="isEditSkills" class="d-flex justify-content-around mt-2 candidate-skills-item pl-1 col-xs-11">
                    <input
                        #newSkillsInput
                        id="skillsInput{{skillType}}-{{i}}"
                        style="font-size: 15px;width:72%"
                        [(ngModel)]="skill.displayName"
                        [typeahead]="skillsObservable"
                        [typeaheadAsync]="true"
                        typeaheadOptionField="displayName"
                        class="d-inline-block mr-3"
                        placeholder="Enter Skill"
                        (typeaheadOnSelect)="onRequisitionSkillSelect($event, skill); skill.typeAheadSelected = true"
                        (focus)="skill.typeAheadSelected = false"
                        [typeaheadOptionsLimit]="8"
                        container="body"
                        [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="8"
                        (ngModelChange)="onSearchSkillChange($event)"
                        (keyup.enter)="newSkill(skill.displayName, 'onEnter', newSkillsInput, skill)"
                        (keyup.tab)="skill.typeAheadSelected = false"
                        autocomplete="off"
                        [disabled]="isViewOnly"
                    >                        
                    <span class="pt-1 d-flex justify-content-end mt-2 mandatory-favorite-icon">
                        <span *ngIf="!skill.niceToHave && !skill.preferred && skill.mandatory"
                            class="cursor-pointer fas fa-asterisk color-red pr-2 pt-1" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(skill)" style="font-size: 14px;">
                        </span>
                        <span *ngIf="!skill.niceToHave && !skill.mandatory && !skill.preferred" class="cursor-pointer" tooltip="Mandatory" container="true" (click)="updateMandatory(skill)">
                            <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                <path class="cls-asterisk" fill="red"
                                    d="m28.89 20.91-5-2.91 4.87-2.86a3.1 3.1 0 0 0 1.14-1.08 3 3 0 0 0-4.09-4.15L21 12.76V7a3 3 0 0 0-6 0v5.76l-4.85-2.85a3 3 0 1 0-3 5.18l5 2.91-4.95 2.86a3.1 3.1 0 0 0-1.14 1.08 3 3 0 0 0 4.09 4.14L15 23.24v5.66a3 3 0 0 0 2 2.94A3 3 0 0 0 21 29v-5.76l4.85 2.85a3 3 0 1 0 3-5.18ZM28.24 24a1 1 0 0 1-1.37.36L19 19.75V29a1 1 0 0 1-2 0v-9.25l-7.87 4.61a1 1 0 0 1-1-1.72L16 18l-7.9-4.64a1 1 0 1 1 1-1.72l7.9 4.61V7a1 1 0 0 1 2 0v9.25l7.87-4.62a1 1 0 0 1 1 1.72L20 18l7.9 4.64a1 1 0 0 1 .34 1.36" />
                                <path fill="none" d="M0 0h36v36H0z" />
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': skill.favorite, 'far': !skill.favorite}"
                            (click)="skill.favorite = !skill.favorite" tooltip="Favorite" container="true">
                        </span>
                        <span *ngIf="!skill.mandatory && !skill.niceToHave"
                            class="cursor-pointer text-success fa-check-circle pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': skill.preferred, 'far': !skill.preferred}"
                            (click)="updatePreferred(skill)" tooltip="Preferred" container="true">
                        </span>
                        <span *ngIf="!skill.mandatory && !skill.preferred"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': skill.niceToHave, 'far': !skill.niceToHave}"
                            (click)="updateNiceToHave(skill)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill mt-1"
                            tooltip="Delete Skill" (click)="deleteSkill(i)" *ngIf="!isViewOnly"></span>
                    </span>
            </div>
            <div *ngIf="(skills.length - 1 > i) && isEditSkills" container="true"
                tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                [ngClass]="{'mt-3': isEditSkills, 'mt-0': !isEditSkills}"
                class="cursor-pointer mt-3 d-flex align-items-center px-0 text-muted font-bold">
                <span *ngIf="skill.priority" (click)="skill.priority = !skill.priority"
                    [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                <span *ngIf="!skill.priority" (click)="skill.priority = !skill.priority"
                    [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
            </div>
        </div>
    </div>

    <div class="col-xs-12 px-0">
        <div class="col-xs-6 mt-3" *ngIf="isEditSkills && !isViewOnly">
            <span class="text-primary d-inline-block m-3 cursor-pointer pl-1" (click)="newSkill('','onAddSkill','skillsInput')"><span
                    class="fas fa-plus"></span> Add Skill</span>
        </div>
        <div class="col-xs-6 d-flex justify-content-end mt-3" *ngIf="isEditSkills && !isViewOnly && isRemoveSectionActive">
            <span class="text-danger d-inline-block m-3 cursor-pointer" (click)="removeSection()"><span
                    class="fas fa-times"></span> Remove Section</span>
        </div>
    </div>
</div>
