import { Component, OnInit, Input } from '@angular/core';
import { AssessmentService } from 'src/app/core/services/assessment.service';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-requisition-card-icons',
  templateUrl: './requisition-card-icons.component.html',
  styleUrls: ['./requisition-card-icons.component.css']
})
export class RequisitionCardIconsComponent implements OnInit {

  @Input() requisitionInfo;
  @Input() isAdditionalDetails;
  @Input() isRowViewActive;
  @Input() showSalarySponsorshipWorkLocations;
  @Input() isSharedRequisitionEdit;
  @Input() isInternalRequisitionEdit;

  assessmentDetails: any = null;
  valueAssessmentDetails: any = null;
  requisitionCardSalaryTooltipDisplayMessage = 'Job Salary not available';
  requisitionCardSponsorshipTooltipDisplayMessage = 'Job Sponsorship not available';
  requisitionCardWorkLocationTooltipDisplayMessage = 'Job Work Location not available';

  isCorporateCompany: boolean = false;
  isTechAssessmentInfoExists: boolean = false;
  isValueAssessmentInfoExists: boolean = false;
  techAssessmentInfoLoading: boolean = true;
  valueAssessmentInfoLoading: boolean = true;

  constructor(private assessmentService: AssessmentService, private companyService: CompanyService) {

  }

  ngOnChanges(changes) {
    if (changes && changes.requisitionInfo && !changes.requisitionInfo.firstChange && changes.requisitionInfo.currentValue && changes.requisitionInfo.previousValue) {
      if (changes.requisitionInfo.currentValue.techAssessmentId != changes.requisitionInfo.previousValue.techAssessmentId) {
        this._getAssessmentDetails(true);
      }

      if (changes.requisitionInfo.currentValue.valueAssessmentId != changes.requisitionInfo.previousValue.valueAssessmentId) {
        this._getValueAssessmentDetails(true);
      }
    }
  }

  ngOnInit() {
    this.isCorporateCompany = this.companyService.isCorporateCompany();

    this._checkTechAssessmentIdExists();
    this._checkValueAssessmentIdExists();
    this._setRequisitionCardIconsTooltipDisplayMessage();
  }

  _checkTechAssessmentIdExists() {
    if (this.requisitionInfo.techAssessmentId) {
      this.isTechAssessmentInfoExists = true;
    }
  }

  _getAssessmentDetails(isRefresh) {
    let requisitionNumber = this.isInternalRequisitionEdit ? this.requisitionInfo.id : this.requisitionInfo.requisitionNumber;
    if (this.assessmentDetails == null || isRefresh) {
      this.assessmentService.getAssessmentDetails(this.requisitionInfo.techAssessmentId, requisitionNumber, (data) => {
        this.assessmentDetails = data;
        this._setTotalDuration();
        this.techAssessmentInfoLoading = false;
      }, (error) => {
      });
    }
  }

  _setTotalDuration() {
    this.assessmentDetails.totalDuration = 0;
    angular.forEach(this.assessmentDetails.sections, (section, key) => {
      this.assessmentDetails.totalDuration = this.assessmentDetails.totalDuration + section.duration;
    });
  }

  _checkValueAssessmentIdExists() {
    if (this.requisitionInfo.valueAssessmentId) {
      this.isValueAssessmentInfoExists = true;
    }
  }

  _getValueAssessmentDetails(isRefresh) {
    let requisitionNumber = this.isInternalRequisitionEdit ? this.requisitionInfo.id : this.requisitionInfo.requisitionNumber;
    if (this.valueAssessmentDetails == null || isRefresh) {
      this.assessmentService.getAssessmentDetails(this.requisitionInfo.valueAssessmentId, requisitionNumber, (data) => {
        this.valueAssessmentDetails = data;
        this._setValueAssessmentDuration();
        this.valueAssessmentInfoLoading = false;
      }, (error) => {
      });
    }
  }

  _setValueAssessmentDuration() {
    this.valueAssessmentDetails.totalDuration = 0;
    angular.forEach(this.valueAssessmentDetails.sections, (section, key) => {
      this.valueAssessmentDetails.totalDuration = this.valueAssessmentDetails.totalDuration + section.duration;
    });
  }

  _setRequisitionCardIconsTooltipDisplayMessage() {
    if (this.requisitionInfo.requisitionSalaryDisplayMessage != 'Not Available') {
      this.requisitionCardSalaryTooltipDisplayMessage = 'Job Salary';
    }

    if (this.requisitionInfo.sponsorships && this.requisitionInfo.sponsorships.length > 0) {
      this.requisitionCardSponsorshipTooltipDisplayMessage = 'Job Sponsorship';
    }

    if (this.requisitionInfo.workLocations && this.requisitionInfo.workLocations.length > 0) {
      this.requisitionCardWorkLocationTooltipDisplayMessage = 'Job Work Locations';
    }
  }

}
