<div class="ngx-modal-header">
    <h4 class="modal-title pull-left">{{modalData.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal(null)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="main-box row px-3 pt-4 pb-3 m-2">
        <div class="col-xs-12 px-0">
            <div class="col-xs-12 pr-4">
                <div class="col-xs-12 px-0">
                    <h4 class="mt-0 mb-3" style="font-size: 14px;">Selected Skills</h4>
                </div>
                <div *ngIf="modalData.type == 'update' && isAlternateSkillExists" class="col-xs-12 px-0">
                    <p class="font-bold pull-right" style="font-size: 12px;">
                        Note: Category and Parent Skill of alternate skills cannot be updated
                    </p>
                </div>
                <div class="col-xs-12 px-0 update-selected-table ">
                    <table class="table table-bordered table-responsive mb-0" id="updateOrDeleteTable">
                        <thead>
                            <tr>
                                <th class="text-center text-nowrap">
                                    Skill Name
                                </th>
                                <th class="text-center">
                                    Category
                                </th>
                                <th class="text-center" *ngIf="modalData.type == 'update'">
                                    Parent Skill
                                </th>
                                <th class="text-center"  *ngIf="modalData.type == 'delete'">
                                    Effected Child Skills
                                </th>
                                <th class="text-center"  *ngIf="modalData.type == 'delete'">
                                    Remarks
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let skill of modalData.allSelectedSkills"
                                [ngClass]="{'alternate-skill':skill.alternate, 'bg-danger': skill.isErrorSkill}">
                                <td class="text-center">
                                    <div>{{skill.skill}}</div>
                                </td>
                                <td class="text-center">
                                    <div>{{this._getSkillCategoryDisplayName(skill.category)}}</div>
                                </td>
                                <td class="text-center" *ngIf="modalData.type == 'update'">
                                    <div>{{(skill.parentSkills && skill.parentSkills[0]) ? skill.parentSkills[0].skill: "-"}}</div>
                                </td>
                                <td class="text-center" *ngIf="modalData.type == 'delete'">
                                    <div *ngIf="skill.effectedChildSkillDisplayMessage != ''">
                                        <span [innerHTML]="skill.effectedChildSkillDisplayMessage"></span>
                                    </div>
                                    <div *ngIf="skill.effectedChildSkillDisplayMessage == ''">-</div>
                                </td>
                                <td class="text-center"  *ngIf="modalData.type == 'delete'">
                                    <div *ngIf="skill.deletingMessage != ''">
                                        <span [innerHTML]="skill.deletingMessage"></span>
                                    </div>
                                    <div *ngIf="skill.deletingMessage == ''">-</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xs-12"  *ngIf="modalData.type == 'update' && isMainSkillExists">
                <div class="col-xs-12 col-sm-6 col-md-5 px-0 pt-4" [ngClass]="{'pointer-events-none opacity-half': isSkillsUpdated}">
                    <div class="col-xs-12 mb-3">
                        Select Category:
                    </div>
                    <div class="col-xs-12">
                        <ng-select name="categorySelect" [items]="modalData.skillCategories" dropdownPosition="bottom"
                            bindLabel="name" placeholder="None selected" [(ngModel)]="updatedSelectedSkillCategory"
                            [closeOnSelect]="true" [searchable]="false" [clearable]="false">
                        </ng-select>
                        <p class="mb-0 mt-2" style="font-size: 12px;">The selected category will be applied to all selected
                            skills.
                        </p>
                    </div>
                </div>
                <div *ngIf="modalData.type == 'update' && isMainSkillExists" 
                    class="col-xs-12 col-sm-6 col-md-5 pr-0 pt-4" [ngClass]="{'pointer-events-none opacity-half': isSkillsUpdated}">
                    <label for="parentSkills" class="control-label mb-0">
                        Parent Skills :
                    </label>
                    <tag-input #parentInput name="parentSkills" [(ngModel)]="selectedParentSkill" [onAdding]="onAddingParentSkill"
                        (onTextChange)="onParentSkillTextChange($event)" [placeholder]="'Enter Parent Skill'"
                        [secondaryPlaceholder]="'Enter Parent Skill'" [maxItems]="1"
                        (onAdd)="updateIsParentSkillRemoved($event)"
                        (onRemove)="updateIsParentSkillRemoved($event)"
                        (onSelect)="updateIsParentSkillRemoved($event)"
                        (keyup.enter)="addSkillOnEnter($event, parentInput, 'parentSkill', addParentSkillTemplate)"
                        (keydown.Tab)="addSkillOnEnter($event, parentInput, 'parentSkill', addParentSkillTemplate)">
                        <ng-template let-item="item" let-index="index">
                            <span class="px-3 d-block" [ngClass]="{'bg-tag-exists': item.isSkillExists}">
                                <span class="pr-2">{{ item.skill }}</span>
                                <delete-icon style="width: 12px; height: 12px;" (click)="parentInput.removeItem(item, index)">
                                </delete-icon>
                            </span>
                        </ng-template>
                        <tag-input-dropdown [autocompleteItems]="modalData.allParentSkills">
                            <ng-template let-item="item" let-index="index">
                                <span *ngIf="item.id != 'addTag'">{{item.skill}}</span>
                                <span *ngIf="item.id == 'addTag'" class="d-block cursor-pointer font-bold" style="width: 100%;"
                                    (click)="openParentModal($event, addParentSkillTemplate)">
                                    <span class="fa fa-plus mr-1"></span>
                                    <span>Add Skill</span>
                                </span>
                            </ng-template>
                        </tag-input-dropdown>
                    </tag-input>
                    <div class="checkbox-nice mt-3" [ngClass]="{'disable-remove-parent': isParentSkillRemovedDisabled}">
                        <input type="checkbox" [(ngModel)]="isParentSkillRemoved" id="removeParentSkill" name="removeParentSkill"
                        [disabled]="isParentSkillRemovedDisabled"/> 
                        <label for="removeParentSkill">
                            Remove Parent Skill
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 px-4 mt-5" *ngIf="skillsNotUpdated && skillsNotUpdated.length > 0">
                <div class="col-xs-12 px-0">
                    <h4 class="mt-0 mb-3 font-bold" style="font-size: 13px;">Could not change parent skill for the following:</h4>
                </div>
                <div class="col-xs-12 px-0 update-selected-table ">
                    <table class="table table-bordered table-responsive mb-0" id="updatedTable">
                        <thead>
                            <tr>
                                <th class="text-center text-nowrap">
                                    Skill Name
                                </th>
                                <th class="text-center text-nowrap">
                                    Remarks
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let skill of skillsNotUpdated">
                                <td class="text-center">
                                    <div>{{skill.skill}}</div>
                                </td>
                                <td class="text-center">
                                    <div>{{skill.reason}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xs-12 text-right pt-4 pb-3">
            <div class="clearfix">
                <button *ngIf="!isSkillsUpdated" type="button" class="btn btn-danger mr-2" (click)="closeModal(null)" [disabled]="isLoading">Cancel</button>
                <button *ngIf="!isSkillsUpdated" type="button" class="btn btn-success" (click)="updateOrDeleteSkills()" 
                    [disabled]="modalData.type == 'update' && !isMainSkillExists">
                    <span *ngIf="modalData.type == 'update'">
                        <span *ngIf="isLoading">Updating...</span>
                        <span *ngIf="!isLoading">Update</span>
                    </span>
                    <span *ngIf="modalData.type == 'delete'">Delete</span>
                </button>
                <button *ngIf="isSkillsUpdated" type="button" class="btn btn-success" (click)="closeModal(modalData)">Ok</button>
            </div>
        </div>
    </div>
</div>
<ng-template #addParentSkillTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            Add Parent Skill
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="parentSkillModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xs-12">
                <form #parentSkillForm="ngForm" (ngSubmit)="addParentSkill(newSkill)" novalidate>
                    <div class="form-group col-xs-12 col-md-6"
                        [ngClass]="{'has-error mb-0': (parentSkillForm.submitted && newParentSkillName.invalid)}">
                        <label for="skillName" class="control-label mb-3">
                            Skill Name<em>*</em> :
                        </label>
                        <input #newParentSkillName="ngModel" type="text" name="newParentSkillName" class="form-control"
                            [(ngModel)]="newSkill.skill" placeholder="Enter Parent Skill" required>
                        <small class="error" *ngIf="(parentSkillForm.submitted && newParentSkillName.invalid)">
                            Parent Skill is mandatory
                        </small>
                    </div>
                    <!-- Alternate Skill -->
                    <div class="form-group col-xs-12 col-md-6">
                        <label for="parentAlternateSkills" class="control-label mb-0">
                            Alternate Skills :
                        </label>
                        <tag-input #parentAlternateInput #parentAlternateSkills="ngModel" name="parentAlternateSkills"
                            [placeholder]="'Enter Alternate Skill'" [secondaryPlaceholder]="'Enter Alternate Skill'"
                            [(ngModel)]="newSkill.alternateSkills" [onAdding]="onAddingParentSkill"
                            (onTextChange)="onAlternateSkillTextChange($event)"
                            (keyup.enter)="addAlternateSkillOnEnter($event, parentAlternateInput)"
                            (keydown.Tab)="addAlternateSkillOnEnter($event, parentAlternateInput)" [addOnBlur]="true"
                            [ngClass]="{'duplicate-skill': newSkill.isAlternateSkillDuplicate}">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-2">
                                    <span class="pr-2">{{ item.skill }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="parentAlternateInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                        </tag-input>
                        <span class="help-block mb-0 ml-2">
                            <span *ngIf="!newSkill.isAlternateSkillDuplicate">Ex. Alternate skill for 'C#'' is 'C
                                Sharp'</span>
                            <span *ngIf="newSkill.isAlternateSkillDuplicate" style="color: red;">Skill Name already
                                exists.</span>
                        </span>
                    </div>
                    <div class="form-group col-xs-12 col-md-6"
                        [ngClass]="{'has-error mb-0': (parentSkillForm.submitted && parentSkillCategory.invalid)}">
                        <label for="parentSkillCategory" class="control-label mb-3">
                            Category<em>*</em> :
                        </label>
                        <select #parentSkillCategory="ngModel" name="parentSkillCategory" class="form-control"
                            [(ngModel)]="newSkill.category" required>
                            <option *ngFor="let category of modalData.skillCategories" [value]="category.value">
                                {{category.name}}
                            </option>
                        </select>
                        <small class="error" *ngIf="(parentSkillForm.submitted && parentSkillCategory.invalid)">
                            Category is mandatory
                        </small>
                    </div>
                    <div class="col-xs-12 text-right">
                        <div class="clearfix">
                            <button type="button" class="btn btn-danger mr-2" (click)="parentSkillModal.hide()">
                                <span>Cancel</span>
                            </button>
                            <button type="submit" class="btn btn-success">
                                Add
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>