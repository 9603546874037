import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { NewRequisitionSkills } from 'src/app/core/models/newRequistionSkills';
import { RequisitionSkill } from 'src/app/core/models/requisitionSkill';

@Component({
  selector: 'app-requisition-skills',
  templateUrl: './requisition-skills.component.html',
  styleUrls: ['./requisition-skills.component.css']
})
export class RequisitionSkillsComponent implements OnInit {

  @Input() currentJobStatus;
  @Input() isUploadRequisition;
  @Input() jobTitle;
  @Input() jobLocation;
  @Input() allExistingSkills;
  @Input() allExistingDegrees;
  @Input() allExistingFieldOfStudy;
  @Input() allExistingRoles;
  @Input() allUpdatedRoles;
  @Input() allExistingCertificates;
  @Input() allExistingJDActionWords;
  @Input() timePeriods;
  @Input() skillsTimePeriods;
  @Input() isSharedReq;
  @Output() showJobProfileCallback =  new EventEmitter<any>();
  @Output() updateLocationCallback = new EventEmitter<any>();

  requisitionSkills: any = [];
  benefitsNotes: any = '';
  editorConfig: any;
  addAllSkills: any = 'education';
  newRequisitionSkill: any = {};
  allEducationsList: any = [];
  educationDepartmentList: any = [];
  experiencesList: any = [];
  certificationsList: any = [];
  ranges: any = [];
  // timePeriods: any = [];
  // skillsTimePeriods: any = [];
  searchSkills: any = [];
  allUpdatedJDActionWords: any = [];

  isLoading: boolean = true;
  isViewOnly: boolean = false;

  constructor(
    private alertsAndNotificationsService: AlertAndNotificationsService
  ) { 
  }

  ngOnInit() {
    this.isViewOnly = this.isSharedReq ? true : false;
    this.editorConfig = {
      toolbar: [
        ['bold', 'italic', 'h1', 'h2', 'h3'],
        ['underline', 'strikeThrough'],
        ['fontSize'],
        ['orderedList', 'unorderedList'],
        ['undo', 'redo'],
        ['paragraph'],
        ['blockquote'],
        ['removeFormat']
      ]
    };
    this.requisitionSkills = new NewRequisitionSkills();
    this.setAllRequisitionSkills();
    this.isLoading = false;
  }

  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
  }

  setAllRequisitionSkills() {
    
    this.ranges = [
      { name: "", value: "" },
      { name: "Atleast", value: "atleast" },
      { name: "Min", value: "min" },
      { name: "Max", value: "max" }
    ];
    this.newRequisitionSkill = new RequisitionSkill('');
    if(!this.isViewOnly) {
      const skillCategories = [
        'educations',
        'experiences',
        'technicalSkills',
        'softSkills',
        'extraSkills',
        'operationalSkills',
        'certifications'
      ];
      skillCategories.forEach(category => {
        this.requisitionSkills[category].push(new RequisitionSkill(''));
      });
    } else {
      this.setViewOnlyRequisitionSkill(); // Set Temporary Data. After Getting APi data update with API
    }
    // Set Skills
    this.allExistingSkills.forEach(skill => {
        skill.competency = skill.skill;
    });
    // Set Degrees
    let equivalentDegreeList = [];
    this.allExistingDegrees.forEach(degree => {
      let tempDegree = JSON.parse(JSON.stringify(degree));
      tempDegree.competency = degree.degreeName + " or equivalent experience";
      tempDegree.name = degree.degreeName + " or equivalent experience";
      tempDegree.degreeName = degree.degreeName + " or equivalent experience";
      equivalentDegreeList.push(tempDegree);
      degree.competency = degree.degreeName;
      degree.name = degree.degreeName + " in ";
    });
    this.allExistingDegrees = [...this.allExistingDegrees, ...equivalentDegreeList];
    // Set Roles
    this.allExistingRoles.forEach(role => {
      role.competency = role.role;
    });
    // Set Certificates
    this.allExistingCertificates.forEach(certificate => {
      certificate.competency = certificate.name;
      certificate.inName = certificate.group + " in "
    });
    // Set Jd Action Words
    this.allExistingJDActionWords.forEach(jdAction => {
      if (jdAction.jobDescriptionLanguageDropdown && jdAction.jobDescriptionLanguageDropdown.length > 0) {
        jdAction.jobDescriptionLanguageDropdown.forEach(actionWord => {
          let actionWordTemp = { name: actionWord, jdAction: jdAction };
          this.allUpdatedJDActionWords.push(actionWordTemp);
        });
      }
    });
  }

  setViewOnlyRequisitionSkill() {
    const educations = [
      'Bachelors in Franchise Management',
      'Bachelors or equivalent experience',
      'Advanced Diploma in Avionics Engineering'
    ];

    const experiences = [
      '5 years of experience in a technical environment',
      '2 years of experience in Software Engineering',
      '1 year of experience as a Web Developer'
    ];

    const technicalSkills = [
      '1 year of HTML',
      '4 years of experience in CSS',
      '4 years of experience in CSS'
    ];

    const operationalSkills = [
      '2 years of Future Sales',
      '5 years of experience in Fundraising',
      '10 years of experience with Fuzzy Clustering'
    ];

    const softSkills = [
      'Maximum 1 year of springboot',
      'Atleast 1 year of experience with Systems Testing'
    ];

    const otherSkills = [
      'Minimum 2 years of experience with Zoom',
      '2 years of Zoology'
    ];

    const certifications = [
      'Marketing Certification in Docker Certified Associate',
      'Microsoft Azure Certification in Dreamweaver CC 2015',
      'Research Certification in Associate Cloud Engineer'
    ];

    educations.forEach(skillName => {
      let newRequisitionSkill: any = new RequisitionSkill('');
      newRequisitionSkill.displayName = skillName;
      this.requisitionSkills.educations.push(newRequisitionSkill);
    });

    experiences.forEach(skillName => {
      let newRequisitionSkill: any = new RequisitionSkill('');
      newRequisitionSkill.displayName = skillName;
      this.requisitionSkills.experiences.push(newRequisitionSkill);
    });

    technicalSkills.forEach(skillName => {
      let newRequisitionSkill: any = new RequisitionSkill('');
      newRequisitionSkill.displayName = skillName;
      this.requisitionSkills.technicalSkills.push(newRequisitionSkill);
    });

    operationalSkills.forEach(skillName => {
      let newRequisitionSkill: any = new RequisitionSkill('');
      newRequisitionSkill.displayName = skillName;
      this.requisitionSkills.operationalSkills.push(newRequisitionSkill);
    });

    softSkills.forEach(skillName => {
      let newRequisitionSkill: any = new RequisitionSkill('');
      newRequisitionSkill.displayName = skillName;
      this.requisitionSkills.softSkills.push(newRequisitionSkill);
    });

    otherSkills.forEach(skillName => {
      let newRequisitionSkill: any = new RequisitionSkill('');
      newRequisitionSkill.displayName = skillName;
      this.requisitionSkills.extraSkills.push(newRequisitionSkill);
    });

    certifications.forEach(skillName => {
      let newRequisitionSkill: any = new RequisitionSkill('');
      newRequisitionSkill.displayName = skillName;
      this.requisitionSkills.certifications.push(newRequisitionSkill);
    });
  }

  skillsSaveCallback(event) {
    if (event && event.length > 0) {
      this.allExistingSkills = event;
    }
  }

  onBenefitsNoteChanged() {

  }

  revertToJobDescriptionFile() {
    let message = "<p>Are you sure?</p><p>All your changes will be lost and will be put back to the uploaded Job Description.</p>";
    this.alertsAndNotificationsService.showConfirm("Revert to Job description file", message, "warning", true, () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes reverted to uploaded Job Description successfully', 'success');
    }, () => {
      //do nothing  
    });
  }

  revertToSavedChanges() {
    let message = "<p>Are you sure?</p><p>All your changes will be lost and will be put back to the previously saved information.</p>";
    this.alertsAndNotificationsService.showConfirm("Revert to Saved changes", message, "warning", true, () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes reverted to saved information successfully', 'success');
    }, () => {
      //do nothing  
    });
  }

  revertToOriginalActivatedVersion() {
    let message = "<p>Are you sure?</p><p>Any changes done since the requisition was originally activated, including versions saved after that and any current changes will be lost. You be returned to the original criteria at the time the Requisition was <b>Activated.</b></p><p>This change cannot be <b>undone.</b></p>";
    this.alertsAndNotificationsService.showConfirm("Revert to Original Activated Version", message, "warning", true, () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes reverted to the original criteria at the time the Requisition was <b>Activated.</b> successfully', 'success');
    }, () => {
      //do nothing  
    });
  }

  onAllSkillsChanges() {
    this.newRequisitionSkill = new RequisitionSkill('');
  }

  updateMandatory(skill) {
    skill.mandatory = !skill.mandatory;
    if (skill.mandatory) {
      skill.niceToHave = false;
    }
  }

  updateNiceToHave(skill) {
    skill.niceToHave = !skill.niceToHave;
    if (skill.niceToHave) {
      skill.mandatory = false;
    }
  }

  showJobProfile() {
    if(this.showJobProfileCallback) {
      this.showJobProfileCallback.emit();
    }
  }

  updateLocation() {
    if(this.updateLocationCallback) {
      this.updateLocationCallback.emit();
    }
  }

  addSkillsToOther(event, type) {
    if (event) {
      this.requisitionSkills.extraSkills.unshift(event);
      let title = "Skill Moved";
      let message = `<b>'${event.skill.skill}'</b> has been moved under <b>'Other'</b>`;
      this.alertsAndNotificationsService.showAlert(title, message, 'warning');
    }
  }

  addSkillsAsPerCategory(event, type) {
    let title = "Skill Moved";
    let categoryHeading = event.skill.category.charAt(0).toUpperCase() + event.skill.category.substring(1).toLowerCase();
    let message = `<b>'${event.skill.skill}'</b> is an <b>'${categoryHeading}'</b> Skill. It has been moved under <b>'${(event.skill.category.toLowerCase() == 'other') ? 'Other' : (categoryHeading + ' Skills')}'</b>`;
    if (event) {
      switch (event.skill.category.toLowerCase()) {
        case 'technical':
          this.requisitionSkills.technicalSkills.unshift(event);
          break;
        case 'operational':
          this.requisitionSkills.operationalSkills.unshift(event);
          break;
        case 'soft':
          this.requisitionSkills.softSkills.unshift(event);
          break;
        case 'other':
          this.requisitionSkills.extraSkills.unshift(event);
          break;
        default:
          this.requisitionSkills.extraSkills.unshift(event);
          break;
      }
      this.alertsAndNotificationsService.showAlert(title, message, 'warning');
    }
  }

}

