import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentInfoModule } from './assessment-info/assessment-info.module';
import { AssignRecruiterModule } from './assign-recruiter/assign-recruiter.module';
import { TooltipModule } from 'ngx-bootstrap';
import { CancelAssessmentModule } from './cancel-assessment/cancel-assessment.module';
import { ViewAssessmentReportModule } from './view-assessment-report/view-assessment-report.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AssessmentInfoModule,
    AssignRecruiterModule,
    CancelAssessmentModule,
    ViewAssessmentReportModule
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class JobMatchActionsModule { }
