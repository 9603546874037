import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { RequisitionSkill } from 'src/app/core/models/requisitionSkill';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';

@Component({
    selector: 'app-add-edit-skill-category',
    templateUrl: './add-edit-skill-category.component.html',
    styleUrls: ['./add-edit-skill-category.component.css']
})
export class AddEditSkillCategoryComponent implements OnInit {

    @Input() skills;
    @Input() skillType;
    @Input() isEditSkills;
    @Input() timePeriods;
    @Input() allExistingSkills;
    @Input() isViewOnly;
    @Input() isRemoveSectionActive;
    @Output() saveCallback = new EventEmitter<any>();
    @Output() cancelCallback = new EventEmitter<any>();
    @Output() setOtherSkillsCallback = new EventEmitter<any>();
    @Output() setSkillsAsPerCategoryCallback = new EventEmitter<any>();
    @Output() removeSectionCallback = new EventEmitter<any>();

    searchSkillText: any = '';
    expandSkills: boolean;
    searchSkills: any = [];

    public skillsObservable:  Observable<any[]>;

    constructor(
        private alertsAndNotificationsService: AlertAndNotificationsService
    ) { }

    ngOnInit() {
        this.skillsObservable = Observable.create((observer: any) => {
            // Runs on every search 
            observer.next(this.searchSkillText);
        }).pipe(
            mergeMap((token: string) => this.getSkillsAsObservable(token))
        );
    }

    getSkillsAsObservable(token: string): Observable<any> {
        const escapedToken = this.escapeRegExp(token);
        const query = new RegExp(escapedToken, 'i');
        this.searchSkills = [];
        this.searchSkills = this.getSkill(token);
        return of(
            this.searchSkills.filter((state: any) => {
                return query.test(state.name);
            })
        );
    }

    escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
    }

    getSkill(searchText) {
        let searchResults = [];
        searchText = searchText.toLowerCase();

        if (this._searchStringHasYearsInfoButNoSkill(searchText, 'skill')) {
            return this._getYearsForSearchString(searchText, 'skill');
        }

        let periodObject;
        let preText = "";
        let isSearchStringHasYears = false;
        let actualSearchText = "";
        angular.forEach(this.timePeriods, (period, key) => {
            if (searchText.includes(period.name.toLowerCase())) {
                isSearchStringHasYears = true;
                preText = period.name;
                actualSearchText = "";
                actualSearchText = searchText.replace(preText.toLowerCase(), "");
                periodObject = angular.copy(period);
            }
        });

        if (!isSearchStringHasYears) {
            actualSearchText = searchText;
        }

        let validSkills = this.allExistingSkills.filter((skill) => {
            return skill && skill.skill && skill.skill.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasYears && validSkills.length > 0) {
            angular.forEach(validSkills, (skill, key) => {
                let addMonthsText = (periodObject &&  periodObject.value.months > 0) ? this.convertMonthsToYearsText(periodObject.value, skill.skill, (preText + skill.skill)) : '';
                searchResults.push({ displayName: preText + skill.skill, convertedDisplayName:addMonthsText, name: preText + skill.skill, experience: periodObject.value, mandatory: false, favorite: false, id: skill.id, skill: skill });
            });
        } else if (validSkills.length > 0) {
            angular.forEach(validSkills, (skill, key) => {
                searchResults.push({ displayName: skill.skill, name: skill.skill, experience: skill.experience, mandatory: false, favorite: false, id: skill.id, skill: skill });
            });
        }
        return searchResults;
    }

    convertMonthsToYearsText(periodValue, skill, displayName) {
        const wordToNumber = {
            "one": 1,
            "two": 2,
            "three": 3,
            "four": 4,
            "five": 5,
            "six": 6,
            "seven": 7,
            "eight": 8,
            "nine": 9,
            "ten": 10,
            "eleven": 11,
            "twelve": 12
        };
    
        // Regex to capture numeric or word-based months, with optional 'plus'/'+' or "At least/Minimum/Maximum"
        let monthsPattern = /(?:Atleast|Minimum|Maximum)?\s*(\d+|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve)\s*(?:\+|plus)?\s*month(?:s)?/i;
    
        let match = displayName.match(monthsPattern);
        
        if (match) {
            let monthsString = match[1].toLowerCase();
            let months = isNaN(monthsString) ? wordToNumber[monthsString] : parseInt(monthsString);  // Convert word to number or use the numeric value
    
            let prefix = '';
            let suffix = '';
            
            // Check for "Atleast", "Minimum", "Maximum" and append them to the prefix
            if (/Atleast/i.test(displayName)) {
                prefix = 'Atleast ';
            } else if (/Minimum/i.test(displayName)) {
                prefix = 'Minimum ';
            } else if (/Maximum/i.test(displayName)) {
                prefix = 'Maximum ';
            }
    
            // Adjust for "plus" or "+" indicating additional months and update suffix
            if (match[0].includes('plus') || match[0].includes('+')) {
                suffix = ' +'; // Add the plus suffix
            }
    
            let years = (months / 12).toFixed(1);  // Convert months to years and round to 1 decimal place
    
            // Extract the skill from the displayName by removing the matched time period
            let skillName = displayName.replace(match[0], '').trim();
    
            return `${prefix}${years}${suffix} years ${skillName}`;
        } else {
            return '';
        }
    }

    _searchStringHasYearsInfoButNoSkill(searchText, type) {
        let yearsResult = [];
        if (type === 'skill') {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        } else {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        }
        return yearsResult.length > 0;
    }

    _getYearsForSearchString(searchText, type) {
        let yearsResult = []
        if (type === 'skill') {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        } else {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        }
        let yearsObjectArray = [];
        angular.forEach(yearsResult, (year, key) => {
            yearsObjectArray.push({ displayName: year.name, name: year.name });
        });
        return yearsObjectArray;
    }

    onRequisitionSkillSelect(e: TypeaheadMatch, skill): void {
        skill.skill = e.item.skill ? e.item.skill : null;
    }

    onSearchSkillChange(searchSkillText: string): void {
        this.searchSkillText = searchSkillText;
    }

    setFocusToNextElement(type, inputEvent, isLastElement) {
        let baseId;
        let currentIndex;
        if (type === 'onAddSkill') {
            baseId = (inputEvent === 'skillsInput') ? (inputEvent + this.skillType) : inputEvent;
            currentIndex = this.skills.length - 1;
        } else {
            inputEvent.blur();
            baseId = inputEvent.id.split('-')[0];
            currentIndex = parseInt(inputEvent.id.split('-')[1]);
        }
        let nextIndex = currentIndex + 1;
        let nextId = `${baseId}-${!isLastElement ? nextIndex : (this.skills.length - 1)}`;
        let nextElement = document.getElementById(nextId);
        nextElement.focus();
    }

    checkAndAddNewSkill(type, inputEvent, inputText, skill) {
        let checkIfEmptyInputFieldExists = this.skills.filter(skill => (!skill.displayName || skill.displayName == ''));
        if (checkIfEmptyInputFieldExists && checkIfEmptyInputFieldExists.length > 0) {
            setTimeout(() => {
                this.setFocusToNextElement(type, inputEvent, true);
            }, 100);
            if (type === 'onAddSkill') {
                this.displayEmptySkillMessage();
            }
        } else {
            if (this.checkIfSkillExists(skill, inputText)) {
                this.onAddSkill(type, skill, inputText, inputEvent);
            } else {
                if (type === 'onAddSkill') {
                    this.skills.push(new RequisitionSkill(''));
                } else {
                    this.displayEmptySkillMessage();
                }
            }

        }
    };

    checkIfSkillExists(skill, inputText) {
        if (this.skillType != 'education' && this.skillType != 'certification') {
            let timePeriodObj = this.timePeriods.filter(timePeriod => inputText.includes(timePeriod.name)).sort((a, b) => b.name.length - a.name.length)[0];
            let existingSkillName = timePeriodObj ? inputText.replace(timePeriodObj.name, '').trim() : inputText;
            return (existingSkillName && existingSkillName != '') ? true : false;
        } else {
            return true;
        }
    }

    newSkill(inputText: string, type: string, inputEvent: any, skill) {
        this.skills.push(new RequisitionSkill(''));
        // if (inputText && inputText !== '') {
        //     if (skill.typeAheadSelected && (skill.oldDisplaySkillName != skill.displayName)) {
        //         this.expandSkills = true;
        //         skill.typeAheadSelected = false;
        //         skill.oldDisplaySkillName = skill.displayName.slice();
        //     } else {
        //         this.expandSkills = true;
        //         this.checkAndAddNewSkill(type, inputEvent, inputText, skill);
        //     }
        // } else {
        //     if (type === 'onEnter') {
        //         this.displayEmptySkillMessage();
        //     } else {
        //         this.expandSkills = true;
        //         this.checkAndAddNewSkill(type, inputEvent, inputText, skill);
        //     }
        // }
    }

    displayEmptySkillMessage() {
        if (this.skillType === 'technical' || this.skillType === 'operational' || this.skillType === 'soft' || this.skillType === 'other') {
            this.alertsAndNotificationsService.showAlert('Skill Empty', "Enter Skill to Add", 'warning');
        } else {
            const title = this.skillType === 'education' ? 'Education' : this.skillType === 'experience' ? 'Experience' : 'Certification';
            this.alertsAndNotificationsService.showAlert(`${title} Empty`, `Enter ${title} to Add`, 'warning');
        }
    }


    onAddSkill(type, skill, inputText, inputEvent) {
        if (this.skillType === 'technical' || this.skillType === 'operational' || this.skillType === 'soft' || this.skillType === 'other') {
            let timePeriodObj = this.timePeriods.filter(timePeriod => (inputText.length > timePeriod.name.length) && (inputText.includes(timePeriod.name))).sort((a, b) => b.name.length - a.name.length)[0];
            // Check if input text has any of existing skills
            let existingSkillName = timePeriodObj ? inputText.replace(timePeriodObj.name, '').trim() : inputText;
            let existingSkill = this.allExistingSkills.filter(skill =>
                existingSkillName.toLowerCase() === skill.skill.toLowerCase()
            );

            if (!existingSkill || existingSkill.length === 0) {
                let newSkill = this.skills.filter(skill => skill.displayName === inputText)[0];
                newSkill.skill = { skill: existingSkillName };
                this.setOtherSkillsCallback.emit(newSkill);
                this.skills = this.skills.filter(skill => skill.displayName !== inputText);
                this.skills.push(new RequisitionSkill(''));
            } else {
                if (existingSkill[0].category.toLowerCase() != this.skillType) {
                    let newSkill = this.skills.filter(skill => skill.displayName === inputText);
                    this.setSkillsAsPerCategoryCallback.emit(newSkill[0]);
                    this.skills = this.skills.filter(skill => skill.displayName !== inputText);
                    this.skills.push(new RequisitionSkill(''));
                } else {
                    this.skills.push(new RequisitionSkill(''));
                    setTimeout(() => {
                        this.setFocusToNextElement(type, inputEvent, false);
                    }, 100);
                }
            }
            if (this.skills.length === 0) {
                this.skills.push(new RequisitionSkill(''));
            }
        } else {
            this.skills.push(new RequisitionSkill(''));
        }
    }

    removeSection() {
        this.removeSectionCallback.emit();
    }

    deleteSkill(index) {
        this.skills.splice(index, 1);
        if(!this.skills || this.skills.length === 0) {
            this.removeSection();
        }
    }

    updateMandatory(skill) {
        skill.mandatory = !skill.mandatory;
        if (skill.mandatory) {
            skill.niceToHave = false;
            skill.preferred = false;
        }
    }

    updateNiceToHave(skill) {
        skill.niceToHave = !skill.niceToHave;
        if (skill.niceToHave) {
            skill.mandatory = false;
            skill.preferred = false;
        }
    }

    updatePreferred(skill) {
        skill.preferred = !skill.preferred;
        if (skill.preferred) {
            skill.mandatory = false;
            skill.niceToHave = false;
        }
    }
}
