import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './generic.service';
import { StateManagementStorageService } from './state-management-storage.service';
import { StorageService } from './storage.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManageSkillsService {

  urlConstants: any = {
    ADD_UPDATE_MOVE_SKILL: 'api/manage/skills',
    GET_ALL_SKILLS: 'api/manage/skills',
    DELETE_SKILL: 'api/manage/skills',
    BULK_DELETE_SKILLS: 'api/manage/skills/bulkDelete',
    GET_ALL_CATEGORIES: 'api/manage/skills/category',
    SKILL_UPLOAD_URL: 'api/manage/skills/upload',
    DOWNLOAD_SKILLS_URL: 'api/manage/skills/download',
    DOWNLOAD_UPLOADED_FILE: 'api/manage/skills/file',
    GET_ALL_SKILL_SORT_CATEGORIES: '../../../assets/app-content/jsonobjects/skillsSort.json',
    GET_ALL_ACTIVITIES: 'api/manage/skills/activity',
    UPLOAD_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees/file',
    GET_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees',
    DOWNLOAD_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees/download',
    ADD_UPDATE_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees',
    BULK_REQUISITION_ENTITIES_DEGREES: 'api/manage/degrees/bulkDelete',
    GET_ALL_DEGREES_ACTIVITIES: 'api/manage/degrees/activity',
    UPLOAD_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy/file',
    GET_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy',
    DOWNLOAD_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy/download',
    ADD_UPDATE_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy',
    BULK_REQUISITION_ENTITIES_FIELD_OF_STUDY: 'api/manage/fieldsOfStudy/bulkDelete',
    GET_ALL_FIELD_OF_STUDY_ACTIVITIES: 'api/manage/fieldsOfStudy/activity',
    UPLOAD_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles/file',
    GET_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles',
    DOWNLOAD_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles/download',
    ADD_UPDATE_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles',
    BULK_REQUISITION_ENTITIES_CANDIDATE_ROLES: 'api/manage/candidateroles/bulkDelete',
    GET_ALL_CANDIDATE_ROLES_ACTIVITIES: 'api/manage/candidateroles/activity',
    UPLOAD_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications/file',
    GET_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications',
    DOWNLOAD_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications/download',
    ADD_UPDATE_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications',
    BULK_REQUISITION_ENTITIES_CERTIFICATIONS: 'api/manage/certifications/bulkDelete',
    GET_ALL_CERTIFICATIONS_ACTIVITIES: 'api/manage/certifications/activity',
    UPLOAD_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords/upload',
    DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords/file',
    GET_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords',
    DOWNLOAD_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords/download',
    ADD_UPDATE_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords',
    BULK_REQUISITION_ENTITIES_JD_ACTIONS: 'api/manage/actionwords/bulkDelete',
    GET_ALL_JD_ACTIONS_ACTIVITIES: 'api/manage/actionwords/activity'
  };

  constructor(
    @Inject('$state') public $state: any,
    @Inject('$rootScope') public $rootScope: any,
    @Inject(StorageService) private StorageService: StorageService,
    @Inject(GenericService) private genericService: GenericService,
    private authService: AuthService
  ) { }

  addAndUpdateAndMoveSkill(skillObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_MOVE_SKILL + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id , skillObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllSkills(skillFilterObject, successCallback, errorCallback) {
    let queryParams = [];
    if (skillFilterObject.searchSkill && skillFilterObject.searchSkill != '') {
      queryParams.push(`searchSkill=${skillFilterObject.searchSkill}`);
    }
    if (skillFilterObject.searchByParentSkill && skillFilterObject.searchByParentSkill != '') {
      queryParams.push(`searchByParentSkill=${skillFilterObject.searchByParentSkill}`);
    }
    if (skillFilterObject.searchCategory && skillFilterObject.searchCategory != '') {
      queryParams.push(`searchCategory=${skillFilterObject.searchCategory}`);
    }
    if (skillFilterObject.sortColumn) {
      queryParams.push(`sortColumn=${skillFilterObject.sortColumn}`);
    }
    if (skillFilterObject.sortDir) {
      queryParams.push(`sortDir=${skillFilterObject.sortDir}`);
    }
    if (!_.isNull(skillFilterObject.includeAlternateSkills) || !_.isUndefined(skillFilterObject.includeAlternateSkills)) {
      queryParams.push(`includeAlternateSkills=${skillFilterObject.includeAlternateSkills}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_SKILLS + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteSkill(skillId, boolean, successCallback, errorCallback) {
    let deleteConfirmed = '';
    if(boolean != null) {
      deleteConfirmed = `?deleteConfirmed=${boolean}`;
    }
    this.genericService.deleteObjectById(this.StorageService.get('baseurl') + this.urlConstants.DELETE_SKILL + '/' + skillId + deleteConfirmed).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteAllSkills(deleteConfirmed, skillIds, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_DELETE_SKILLS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id + `?deleteConfirmed=${deleteConfirmed}`, {skills: skillIds}).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllCategories(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CATEGORIES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSkillFileUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.SKILL_UPLOAD_URL + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getRequisitionEntitiesDegreesUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_DEGREES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  downloadSkillFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_SKILLS_URL + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllSkillSortCategories(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_SKILL_SORT_CATEGORIES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  updateAllSkills(updatedSkills, successCallback, errorCallback) {
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_MOVE_SKILL + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, updatedSkills).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  downloadUploadedFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_FILE + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllDegreesEntityLists(degreeFilterObject, successCallback, errorCallback) {
    let queryParams = [];
    if (degreeFilterObject.searchDegree && degreeFilterObject.searchDegree != '') {
      queryParams.push(`searchDegree=${degreeFilterObject.searchDegree}`);
    }
    if (degreeFilterObject.sortColumn) {
      queryParams.push(`sortColumn=${degreeFilterObject.sortColumn}`);
    }
    if (degreeFilterObject.sortDir) {
      queryParams.push(`sortDir=${degreeFilterObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_DEGREES + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadDegreesFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_DEGREES + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateDegree(degree, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_DEGREES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, degree).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateDegrees(updateDegree, successCallback, errorCallback) {
    let payload = {
      "entities": updateDegree
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_DEGREES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteDegree(deletedDegree, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_DEGREES + '/' + deletedDegree.id, deletedDegree).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteDegrees(deletedDegrees, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_DEGREES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedDegrees }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllDegreesActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_DEGREES_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  downloadUploadedDegreesFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_DEGREES + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getFieldOfStudyUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getAllFieldOfStudyLists(degreeFilterObject, successCallback, errorCallback) {
    let queryParams = [];
    if (degreeFilterObject.searchFieldOfStudy && degreeFilterObject.searchFieldOfStudy != '') {
      queryParams.push(`searchFieldOfStudy=${degreeFilterObject.searchFieldOfStudy}`);
    }
    if (degreeFilterObject.sortColumn) {
      queryParams.push(`sortColumn=${degreeFilterObject.sortColumn}`);
    }
    if (degreeFilterObject.sortDir) {
      queryParams.push(`sortDir=${degreeFilterObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_FIELD_OF_STUDY + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadFieldOfStudyFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_FIELD_OF_STUDY + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateFieldOfStudy(fieldOfStudy, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, fieldOfStudy).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateFieldOfStudy(updateFieldOfStudy, successCallback, errorCallback) {
    let payload = {
      "entities": updateFieldOfStudy
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteFieldOfStudy(deletedFieldOfStudy, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + deletedFieldOfStudy.id, deletedFieldOfStudy).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteFieldOfStudy(deletedFieldOfStudyEntities, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_FIELD_OF_STUDY + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedFieldOfStudyEntities }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllFieldOfStudyActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_FIELD_OF_STUDY_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  downloadUploadedFieldOfStudyFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_FIELD_OF_STUDY + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadCandidateRolesFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_CANDIDATE_ROLES + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateCandidateRoles(candidateRoles, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, candidateRoles).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateCandidateRoles(updateCandidateRoles, successCallback, errorCallback) {
    let payload = {
      "entities": updateCandidateRoles
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteCandidateRoles(deletedCandidateRoles, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + deletedCandidateRoles.id, deletedCandidateRoles).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteCandidateRoles(deletedCandidateRolesEntities, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedCandidateRolesEntities }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllCandidateRolesActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CANDIDATE_ROLES_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  getCandidateRolesUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_CANDIDATE_ROLES + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getAllCandidateRolesLists(roleObject, successCallback, errorCallback) {
    let queryParams = [];
    if (roleObject.searchRole && roleObject.searchRole != '') {
      queryParams.push(`searchRole=${roleObject.searchRole}`);
    }
    if (roleObject.sortColumn) {
      queryParams.push(`sortColumn=${roleObject.sortColumn}`);
    }
    if (roleObject.sortDir) {
      queryParams.push(`sortDir=${roleObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_CANDIDATE_ROLES + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadUploadedRolesFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_CANDIDATE_ROLES + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  
  downloadCertificationsFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_CERTIFICATIONS + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateCertifications(certifications, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, certifications).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateCertifications(updateCertifications, successCallback, errorCallback) {
    let payload = {
      "entities": updateCertifications
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteCertifications(deletedCertifications, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + deletedCertifications.id, deletedCertifications).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteCertifications(deletedCertificationsEntities, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedCertificationsEntities }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllCertificationsActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CERTIFICATIONS_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  getCertificationsUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_CERTIFICATIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getAllCertificationsLists(certificateObject, successCallback, errorCallback) {
    let queryParams = [];
    if (certificateObject.searchName && certificateObject.searchName != '') {
      queryParams.push(`searchName=${certificateObject.searchName}`);
    }
    if (certificateObject.sortColumn) {
      queryParams.push(`sortColumn=${certificateObject.sortColumn}`);
    }
    if (certificateObject.sortDir) {
      queryParams.push(`sortDir=${certificateObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_CERTIFICATIONS + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadUploadedCertificationsFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_CERTIFICATIONS + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  
  downloadJdActionsFile(fileExtension, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_REQUISITION_ENTITIES_JD_ACTIONS + '?fileExtension=' + fileExtension).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addUpdateJdActions(jdActions, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_JD_ACTIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, jdActions).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkAddUpdateJdActions(updateCertifications, successCallback, errorCallback) {
    let payload = {
      "entities": updateCertifications
    };
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_JD_ACTIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteJdActions(deletedJdActions, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_REQUISITION_ENTITIES_JD_ACTIONS + '/' + deletedJdActions.id, deletedJdActions).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  bulkDeleteJdActions(deletedJdActionsEntities, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.BULK_REQUISITION_ENTITIES_JD_ACTIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, { entities: deletedJdActionsEntities }).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllJdActionsActivities(activityFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_JD_ACTIONS_ACTIVITIES, activityFilter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }  

  getJdActionsUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_REQUISITION_ENTITIES_JD_ACTIONS + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id;
  }

  getAllJdActionsLists(jdActionsObject, successCallback, errorCallback) {
    let queryParams = [];
    if (jdActionsObject.searchName && jdActionsObject.searchName != '') {
      queryParams.push(`searchActionWord=${jdActionsObject.searchName}`);
    }
    if (jdActionsObject.sortColumn) {
      queryParams.push(`sortColumn=${jdActionsObject.sortColumn}`);
    }
    if (jdActionsObject.sortDir) {
      queryParams.push(`sortDir=${jdActionsObject.sortDir}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_ENTITIES_JD_ACTIONS + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  downloadUploadedJdActionsFile(activityId, successCallback, errorCallback) {
    this.genericService.downloadFile(this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_UPLOADED_REQUISITION_ENTITIES_JD_ACTIONS + "/" + activityId + "/download").subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  // Shared Data
  setTimePeriodsInRequisitionSkillsTypeAhead() {
    let timePeriods = [];
    let a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    let b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    for (let i = 1; i < 100; i++) {
      let num: any = i;
      if ((num = num.toString()).length > 9) return 'overflow';
      let n: any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return; let str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
      let yearText = "";
      let monthText = "";
      if (num > 1) {
        yearText = "years of ";
        monthText = "months of ";
      } else {
        yearText = "year of ";
        monthText = "month of ";
      }
      this.setYearsOfText(num, yearText, str, i, "", timePeriods, false);
      if(num < 12) {
        this.setYearsOfText(num, monthText, str, i, "", timePeriods, true);
      }

      let yearPlus = "plus year of ";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      let monthPlus = "plus month of ";
      if(num < 12) {
        this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      }

      yearPlus = "plus years of ";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      monthPlus = "plus months of ";
      if(num < 12) {
        this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      }

      yearPlus = "+ year of ";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      monthPlus = "+ month of ";
      if(num < 12) {
        this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      }

      yearPlus = "+ years of ";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      monthPlus = "+ months of ";
      if(num < 12) {
        this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      }

      yearPlus = " + year of";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      monthPlus = " + month of";
      if(num < 12) {
        this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      }

      yearPlus = " + years of";
      this.setYearsOfText(num, yearPlus, str, i, "", timePeriods, false);
      monthPlus = " + months of";
      if(num < 12) {
        this.setYearsOfText(num, monthPlus, str, i, "", timePeriods, true);
      }
    }
    this.setFromToYearsOfText(timePeriods);
    return timePeriods;
  }

  numToText(num) {
    let a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    let b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    if (num < 20) {
      return a[num].trim();
    } else {
      return (b[Math.floor(num / 10)] + ' ' + a[num % 10]).trim();
    }
  }
  

  setFromToYearsOfText(timePeriods) {
    for (let i = 1; i <= 50; i++) {
      for (let j = i + 1; j <= 50; j++) {
        let yearStartText = this.numToText(i);
        let yearEndText = this.numToText(j);
        let numericName = `${i} to ${j} years of `;
        let textName = `${yearStartText} to ${yearEndText} years of `;
        this.setYearsOfText("",numericName,"", i, j, timePeriods, false);
        this.setYearsOfText("",textName,"", i, j, timePeriods, false);
      }
    }
  }

  setYearsOfText(num, yearText, str,fromYear, toYear, timePeriods, isMonthText) {
      let stringText = str + yearText;
      let isPlusToExists = (yearText.includes("+") ||  yearText.includes("plus"));
      let numText = "";
      if(yearText.includes("+")) {
        numText = num + yearText;
      } else {
        numText = num + " " + yearText;
      }
      let isToExists = (yearText.includes(" to "));
      timePeriods.push({ name: stringText, value: { fromYear: isMonthText ? num/12 : fromYear, toYear: toYear, months: isMonthText ? num : 0, range: "", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: numText, value: { fromYear: isMonthText ? num/12 : fromYear, toYear: toYear, months: isMonthText ? num : 0, range: "", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Minimum ' + str + yearText, value: { fromYear: isMonthText ? num/12 : fromYear, toYear: toYear, months: isMonthText ? num : 0, range: "min", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Maximum ' + str + yearText, value: { fromYear: isMonthText ? num/12 : fromYear, toYear: toYear, months: isMonthText ? num : 0, range: "max", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Atleast ' + str + yearText, value: { fromYear: isMonthText ? num/12 : fromYear, toYear: toYear, months: isMonthText ? num : 0, range: "atleast", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Minimum ' + numText, value: { fromYear: isMonthText ? num/12 : fromYear, toYear: toYear, months: isMonthText ? num : 0, range: "min", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Maximum ' + numText, value: { fromYear: isMonthText ? num/12 : fromYear, toYear: toYear, months: isMonthText ? num : 0, range: "max", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
      timePeriods.push({ name: 'Atleast ' + numText, value: { fromYear: isMonthText ? num/12 : fromYear, toYear: toYear, months: isMonthText ? num : 0, range: "atleast", plusTo: isPlusToExists ? '+' : isToExists ? 'to' : "" } });
  }

  setOfWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'of ';
    });
    return timePeriods;
  }

  setInWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'in ';
    });
    return timePeriods;
  }

  setExperienceInWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'experience ';
    });
    
    return timePeriods;
  }

  setExperienceAndInWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'experience in ';
    });
    
    return timePeriods;
  }

  setExperienceAndWithWordToTimePeriods(timePeriods) {
    timePeriods.forEach(timePeriod => {
      timePeriod.name = timePeriod.name + 'experience with ';
    });
    
    return timePeriods;
  }

}
