export class RequisitionSkill {
    id: any;
    title: any = '';
    degreeName: any = '';
    fieldOfStudy: any = '';
    fromYear: any = '';
    plusTo: any = '';
    toYear: any = '';
    competency: any = '';
    mandatory: boolean = false;
    favorite: boolean = false;
    niceToHave: boolean = false;
    preferred: boolean = false;
    priority: boolean = false;
    displayName: "";
    jdActionWord: "";
    constructor(text) {
        this.degreeName = text;
    }
}