<div [ngClass]="{'col-xs-11': requisitionEntity === 'jdActions','col-xs-10': requisitionEntity === 'degrees', 
    'col-xs-6': requisitionEntity === 'fieldOfStudy' || requisitionEntity === 'roles',
    'col-xs-8': requisitionEntity === 'certificates'}">
    <div class="main-box clearfix">
        <div class="pt-4">
            <div class="col-xs-12 pb-2">
                <div class="col-xs-4">
                    <div class="my-2">
                        Search by {{setSingleEntityTitle(requisitionEntity)}} Name:
                    </div>
                    <div class="">
                        <div class="">
                            <input type="text" name="searchSkill" id="searchSkill" class="form-control"
                                [(ngModel)]="searchEntity" placeholder="Type to Filter"
                                autocomplete="off" (input)="onSearchTextChange($event)" (keyup.enter)="queryEntitySearches()">
                        </div>
                    </div>
                </div>
                <div class="col-xs-8">
                    <div class="mr-2 d-flex align-items-center pull-right">
                        <h1 class="mb-0">
                            <i class="fa fa-list mr-2 cursor-pointer" style="font-size: smaller;" placement="top" tooltip="View Activities" container="body"
                             (click)="viewRequisitionEntitiesActivities(requisitionEntitiesActivitiesTemplate)"></i>
                        </h1>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 pr-4 d-flex justify-content-end">
                <div class="btn-group">
                    <button type="button" class="btn btn-primary" data-toggle="dropdown">Action</button>
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <span class="caret"></span>
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu">
                        <li>
                            <a class="pl-4" (click)="addEntity(addEntityTemplate)">
                                <span class="fa fa-plus skill-action"></span>
                                <span class="pl-3">Add New {{setSingleEntityTitle(requisitionEntity)}}</span>
                            </a>
                        </li>
                        <li role="separator" class="divider my-1"></li>
                        <li>
                            <a class="pl-4" (click)="uploadOrDownloadFile()">
                                <span class="fa fa-upload skill-action"></span>
                               <span class="pl-3">Upload File</span>
                            </a>
                        </li>
                        <li>
                            <a class="pl-4" (click)="downloadAllRequisitionEntities(downloadRequisitionEntityTemplate)">
                                <span class="fa fa-download skill-action"></span>
                                <span class="pl-3">Download File</span>
                            </a>
                        </li>
                        <li>
                            <a class="pl-4" (click)="viewUploadedRequisitionEntities(viewUploadedRequisitionEntitiesTemplate)">
                                <span class="far fa-eye skill-action"></span>
                               <span class="pl-3">View Uploaded Files</span>
                            </a>
                        </li>
                        <li role="separator" class="divider my-1"></li>
                        <li>
                            <a class="pl-4" (click)="deleteAllEntitiesPage()">
                                <span class="far fa-trash-alt skill-action"></span>
                                <span class="pl-3">Delete All {{setMultipleEntitiesTitle(requisitionEntity)}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="px-0 mt-4">
                    <div class="main-box">
                        <div class="main-body px-4 py-2">
                            <ngx-datatable class="material manage-skills-datatable manage-requisition-entities"
                                [rows]="allEntitiesList" [columns]="columns" [columnMode]="setColumnMode" [headerHeight]="43"
                                [footerHeight]="50" rowHeight="'auto'" [count]="page.totalElements" [scrollbarV]="true"
                                [virtualization]="false" [externalPaging]="true" [offset]="page.pageNumber"
                                [loadingIndicator]="isEntityLoading" [limit]="page.size" (page)="setTablePage($event)"
                                (sort)="onSort($event)">
                            </ngx-datatable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #downloadRequisitionEntityTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <span>Download {{setMultipleEntitiesTitle(requisitionEntity)}}</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close"
            (click)="closeDownloadRequisitionEntitiesModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <div class="form-group">
                    <label for="downloadFileFomart" class="control-label mb-3">
                        Select Download File Format :
                    </label>
                    <select #skillCategory="ngModel" name="downloadFileFormat" class="form-control"
                        [(ngModel)]="downloadSkillFormat">
                        <option value="xls">Excel
                        </option>
                        <option value="csv">CSV
                        </option>
                    </select>
                </div>
            </div>
            <div class="text-right col-xs-12">
                <div class="clearfix">
                    <button type="button" class="btn btn-danger mr-2" (click)="closeDownloadRequisitionEntitiesModal()">
                        <span>Cancel</span>
                    </button>
                    <button class="btn btn-success" (click)="downloadAllRequisitionEntitiesFile(downloadSkillFormat)">
                        Download
                    </button>
                </div>
            </div>
        </div>
    </div>

</ng-template>
<ng-template #addEntityTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <span>Add {{setSingleEntityTitle(requisitionEntity)}}</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeAddEntityModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-add-edit-entities action="add" [entityType]="requisitionEntity" [entityData]="addEditEntityData"
            [allRequisitionEntities]="requisitionEntitiesList" (saveAddEntityCallback)="saveAddEntity($event)"
            (cancelCallback)="closeAddEntityModal()">
        </app-add-edit-entities>
    </div>
</ng-template>

<ng-template #editEntityTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <span>Edit {{setSingleEntityTitle(requisitionEntity)}}</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeEditEntityModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-add-edit-entities action="edit" [entityType]="requisitionEntity" [entityData]="addEditEntityData"
            [allRequisitionEntities]="requisitionEntitiesList" (saveEditEntityCallback)="saveEditEntity($event)"
            (cancelCallback)="closeEditEntityModal()">
        </app-add-edit-entities>
    </div>
</ng-template>

<ng-template #entityActionsTemplate let-row="row">
    <div class="d-flex flex-nowrap">
        <a class="table-link primary cursor-pointer" placement="top"
            tooltip="Edit {{setSingleEntityTitle(requisitionEntity)}}" container="body" [adaptivePosition]="false"
            (click)="editEntity(editEntityTemplate, row)">
            <span class="fa-stack">
                <i class="fa fa-square fa-stack-2x"></i>
                <i class="fa-stack-1x fa-inverse"
                    [ngClass]="{'far fa-eye': nonEditable, 'fas fa-pencil-alt': !nonEditable}"></i>
            </span>
        </a>
        <a style="color: #e84e40;" class="table-link danger cursor-pointer" placement="top"
            tooltip="Delete {{setSingleEntityTitle(requisitionEntity)}}" container="body" [adaptivePosition]="false"
            (click)="deleteEntity(row)">
            <span class="fa-stack">
                <i class="fa fa-square fa-stack-2x"></i>
                <i class="far fa-trash-alt fa-stack-1x fa-inverse"></i>
            </span>
        </a>
    </div>
</ng-template>

<ng-template #requisitionEntitiesActivitiesTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <span>{{setMultipleEntitiesTitle(requisitionEntity)}} Activities</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close"
            (click)="requisitionEntitiesActivitiesTemplateModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-req-entities-activities [entityType]="requisitionEntity"
            [multiEntityHeading]="setMultipleEntitiesTitle(requisitionEntity)"
            [singleEntityHeading]="setSingleEntityTitle(requisitionEntity)"></app-req-entities-activities>
    </div>
</ng-template>

<ng-template #viewUploadedRequisitionEntitiesTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <span>View Uploaded {{setMultipleEntitiesTitle(requisitionEntity)}}</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="viewUploadedEntitiesModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-view-upload-req-entities [entityType]="requisitionEntity"
            [multiEntityHeading]="setMultipleEntitiesTitle(requisitionEntity)"
            [singleEntityHeading]="setSingleEntityTitle(requisitionEntity)">
        </app-view-upload-req-entities>
    </div>
</ng-template>