import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditCandidateSkillComponent } from './add-edit-candidate-skill.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopoverModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';  
import { AddEditSkillCategoryComponent } from '../add-edit-skill-category/add-edit-skill-category.component';

@NgModule({
  declarations: [AddEditCandidateSkillComponent, AddEditSkillCategoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    DragDropModule
  ],
  exports: [AddEditCandidateSkillComponent, AddEditSkillCategoryComponent],
  entryComponents: [AddEditCandidateSkillComponent, AddEditSkillCategoryComponent]
})
export class AddEditCandidateSkillModule { }
